import { createGlobalStyle } from "styled-components";
import FKRoman from "../assets/fonts/FKRomanStandard.woff2";
import PitchMedium from "../assets/fonts/pitch-sans-web-medium.woff2";
import PitchMediumItalic from "../assets/fonts/pitch-sans-web-medium-italic.woff2";
import PitchBold from "../assets/fonts/pitch-sans-web-bold.woff2";
import Diamond from "../assets/img/Diamond.svg";
import Dot from "../assets/img/Dot.svg";
import Ornaments from "../assets/fonts/MBMB_Ornaments-Thin_web.woff2";

const media = {
  medium: "@media(min-width:700px)",
  large: "@media(min-width:1200px)",
  xLarge: "@media(min-width:1800px)",
};

const GlobalStyles = createGlobalStyle`
    
    @font-face {
      font-family: "Pitch Sans";
      src: url(${PitchMedium});
      
    }

    @font-face {
        font-family: "Pitch Sans" ;
        src: url(${PitchMediumItalic});
        font-style: italic;
    }

    @font-face {
        font-family: "Ornaments";
        src: url(${Ornaments});

    }

    @font-face {
        font-family: "Pitch Sans";
        src: url(${PitchBold});
        font-weight: bold;
        font-style:normal;
    }

    @font-face{
        font-family:"FK Roman";
        src: url(${FKRoman});
    }
    html{
        font-size:var(--body-text);
    }
    body{
        background:#FBFBEF;
        font-size:var(--body-text);

    }


    ::selection{
        background:black;
        color:#FBFBEF;
    }

    p, li, address, button, a, label{
        font-family:"Pitch Sans", menlo, monospace;
        font-size:var(---body-text);
    }

    p, address{
        a{
            text-decoration:none;
            color:black;
            border-bottom: 1px solid black;
        }
    }

    h2,h3,h4{
        font-family: "FK Roman", 'Times New Roman', Times, serif;
        font-weight:600;
    }

   

    button{
        font-family:var(--serif-text);
        font-variation-settings: 'wght' 500;
    }

    h1{
        
        padding-top:0.5rem;
        font-size:var(--body-text);
        font-family: var(--sans-text);
        font-weight:normal;
        text-align:center;
        text-transform:uppercase;
        margin-top:10vh;

        ${media.medium}{
            margin-bottom:20vh;
            margin-top:20vh;

        }
        &:before,
        &:after{
            content:'';
            /* background-color:yellow; */
            background-image:url(${Diamond});
            background-size:cover;
            width:11px;
            height:11px;
            /* border-bottom:1px solid black; */
            display:inline-block;

        }

        &:before{
            margin-right:6px;
        }

        &:after{
            content:'';
            margin-left:6px;
            background-image:url(${Dot});
            background-size:cover;

        }


    }

    

    .video-container{
        /* background:green; */
        min-height:100vh;
        display:grid;
        align-items:center;
        justify-content: center;
        text-align:center;
    }

    .video{
        max-width:100%;

        height:auto;
        /* margin-top:10vh; */
        box-shadow:0px 0px 4px rgba(0,0,0,0.1);

        ${media.medium}{
            max-width:103%;
            margin-left:-4%;
        }

        ${media.large}{
            max-width:105%;
        }

        ${media.xLarge}{

        }
    }

    h2{
        font-weight:520;
        letter-spacing:-0.02em;
        font-size:24px;
   
    }

    h3{
        font-size:var(--body-text);
        font-family: var(--sans-text);
        font-weight: "normal";
        text-transform:uppercase;
        margin-top:2em;
    }

    h4{
        font-size:var(--body-text);
        font-family: var(--sans-text);
        font-weight:bold;
        text-transform:title;
        margin-bottom:0;

    }



    h4 < li{
        margin-bottom:0;
    }

    ul{
        margin-bottom:36px;
        padding:0;
        
        li{
            margin-bottom:0;
        }
    }

    textarea{
        width:100%;
        background: #FBFBEF;
        border:1px solid #C4C4C4;
        min-height:130px;

        &:focus{
            outline:2px solid black;
        }
    }

    input[type="radio"]{
        margin-left:10px;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"]{
        background:#FBFBEF;
        border:1px solid #C4C4C4;
        width:100%;
        padding:0.5em;

        &:focus{
            outline: 2px solid black;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .attendees{
        display:grid;

        grid-gap:1em;
        grid-template-columns:1fr 1fr;
        
        label{
            margin-top:0;
        }
    }

    // label group element
    .radio-button{
        display:inline-flex;
        align-items:center;
        cursor: pointer;
        margin-right: 40px;
    }

    .radio_input{
        display:none;
    }

    // new element
    .styled-radio{
        width:1em;
        height:1em;
        border:1px solid black;
        border-radius:50%;
        margin-right:10px;
        box-sizing:border-box;
        padding:2px;

        &:after{
            content:"";
            width:100%;
            height:100%;
            display:block;
            background:black;
            border-radius:50%;
            transform:scale(0);
            transition: all 0.2s ease-in-out;
        }
    }

    .radio_input:checked + .styled-radio:after{
        transform:scale(1.0);
    }

    .wrapper form p,
    .wrapper form h3{
        margin-left:0;
    }


    .checkbox{
        display: inline-flex;
        align-items: top;
        cursor:pointer;
    }

    .checkbox__input{
        display:none;
    }
    
    .checkbox__box{
        width: 1em;
        height: 1em;
        border: 1px solid #C4C4C4;
        border-radius: 3px;
        display: flex;
        align-items:center;
        justify-content:center;
        flex-shrink: 0;
        margin-top:0.133333em;
        transition: background 0.15s, border-color 0.15s;

    }

    .box__description{
        margin-left:0.75em;
    }

    .checkbox__box:after{
        content:'✓';
        color:white;

        transform: scale(0);
        transition: transform 0.15s;
    }

    .checkbox__input:checked + .checkbox__box {
        background:black;
        border:1px solid black;
        &:after{
            transform:scale(1);
        }
    }

    .restrictions{
        margin-top:72px;
    }
    .day{
        /* margin-bottom:20px;
        margin-top: 20px;
        padding-left:2em;
        padding-top:30px;
        padding-bottom:30px; */
        padding: 20px 0 20px 0;
        &:nth-child(even){
            /* border-top:1px solid #C4C4C4; */
            /* border-bottom:1px solid #C4C4C4; */
        }
        input[type="checkbox"]{
            margin-right:1em;

            ${media.medium}{
            margin-left:-1.8em;

            }
        }
        p{
            label{
                margin-top:1em;
                /* background:red; */

            }
        }
    }

   





    .wrapper, footer{
        box-sizing:border-box;
        ${media.medium}{
            margin:0 0 0 50vw;
            width:calc(50vw - 2rem);
            
        }

        ${media.large}{
            padding-left:4rem;
            padding-right:4rem;
        }

        ${media.xLarge}{
            margin:0 0 0 40vw;
            width:40vw;
            padding-left:6em;
            padding-right:6em;
        }

        address{
            font-style:normal;
        }
        
        p,h3,address,h4,li{
            ${media.medium}{
                margin-left:0.5em;
                
            }
        }

        h2{
            margin-bottom:0;
            margin-top:72px;
        }

        h2 + p{
            margin-top:14px;
        }

        p + address{
            margin:0;
            background:red;
        }
    }

    footer{
        font-family:var(--sans-text);
        font-size:var(--small-text);
        font-weight:bold;
        margin-bottom:60px;

        a{
            text-decoration:none;
            color:black;
            border-bottom:1px solid black;
        }
    }

    :root{
        --small-text:13pfx;
        --body-text: 16px;
        --large-text: 38px;
        --x-large-text:48px;
        --sans-text: "Pitch Sans", Helvetica, Arial, sans-serif;
        --serif-text: "FK Roman", "Timews New Roman", serif;
        --light-text: 'wght' 100;

        ${media.medium}{
            --small-text:14px;
            --body-text: 19px;
            --large-text: 42px;
            --x-large-text:72px;


        }
    }
`;

export default GlobalStyles;
