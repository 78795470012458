import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "styled-components";

const media = {
  medium: "@media(min-width:700px)",
  large: "@media(min-width:1200px)",
  xLarge: "@media(min-width:1800px)",
};

const SiteTitle = styled.h1`
  font-family: "FK Roman";
  font-weight: 100;
  text-align: center;
  font-size: 16vw;

  margin-top: 0;
  padding: 0;
  letter-spacing: -0.05em;

  ${media.medium} {
    font-size: 12vw;
  }

  ${media.xLarge} {
    font-size: 10vw;
  }

  .bullet {
    /* background:yellow; */
    display: inline-block;
    transform-origin: 55% 34%;
    animation: spin 10s linear infinite;
    /* transform:rotate(14deg); */
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &:before,
  &:after {
    background: none;
  }

  .ornament {
    font-family: "Ornaments";
  }
`;

const TitleContainer = styled.div`
  ${media.medium} {
    position: relative;
    height: 100vh;
  }
`;

const WeddingDate = styled.h1`
  font-family: "FK Roman";
  font-size: 16vw;
  text-align: center;

  bottom: 0px;
  width: 100%;
  margin: 0;
  font-weight: 100;
  letter-spacing: -0.05em;
  visibility: hidden;

  ${media.medium} {
    position: absolute;
    visibility: visible;
    font-size: 12vw;
  }

  .ss02 {
    font-feature-settings: "ss01";
    margin-right: 0.05em;
    /* background:red; */
  }
  .ornament {
    font-family: "Ornaments";
  }

  ${media.xLarge} {
    font-size: 10vw;
  }
  &:before,
  &:after {
    background: none;
  }
`;

const SiteHeader = styled.header`
  height: 10vh;
  ${media.medium} {
    background: rgba(0, 168, 137, 1);
    background: #04998e;
    background: color(display-p3 0 0.548627451 0.5254901961);
    width: 50vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    border-right: 2px solid #15a68a;
    border-right: 2px solid color(display-p3 0 0.518627451 0.5054901961);
    box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.15);
  }

  ${media.xLarge} {
    width: 40vw;
  }
`;

const SiteNav = styled.nav`
  .button-container {
    position: fixed;
    width: 100%;
    top: 1em;
    right: 1em;
    z-index: 4;
    text-align: right;

    .menu-toggle {
      padding: 0.5em;
      font-family: "Pitch Sans";
      background: none;
      border: 0.1em solid black;
      background: #fbfbef;

      ${media.xLarge} {
        visibility: hidden;
      }

      &.active {
        color: black;
        border: 0.1em solid black;
        background: black;
        background: #ffd815;
        background: color(display-p3 1 0.7647058824 0.05098039216);
      }
    }
  }

  .nav-list {
    background: #ffd815;
    background: color(display-p3 1 0.7647058824 0.05098039216);
    list-style: none;
    z-index: 3;
    position: fixed;
    box-shadow: inset 7px 0 20px -7px rgba(0, 0, 0, 0.12);
    top: 0;
    right: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.57, 0.11, 0.2, 1) 0.1s;
    padding: 1rem;
    font-family: var(--serif-text);
    text-transform: uppercase;
    font-variation-settings: var(--light-text);
    letter-spacing: -0.04em;
    display: grid;
    align-items: center;
    padding-top: 12vw;
    padding-bottom: 12vw;

    ${media.xLarge} {
      transform: translateX(0%);
      width: 20vw;
      left: 80vw;
      right: 0;
    }

    &.visible {
      transform: translateX(0%);
      ${media.medium} {
        transform: translateX(50%);
      }

      ${media.xLarge} {
        transform: translateX(0%);
      }
    }

    li {
      a {
        color: black;
        display: block;
        font-size:var(--large-text);
        padding: 0.25em;
        line-height: 0.8;
        text-decoration: none;
        margin-left: -1em;

        transition: padding 0.3s ease-in-out;

        &:before {
          content: "→ ";
          opacity: 0;
          width: 0px;
          // display:none;
          transition: opacity 0.3s ease-in-out;
        }

        &:hover {
          padding-left: 1em;
        }

        &:hover:before {
          content: "→ ";
          opacity: 1;
          width: auto;
          // display:inline;
        }
        ${media.medium}{
          font-size: var(--large-text);

        }
        ${media.xLarge} {
          font-size: 24px;
        }
      }
    }
  }
`;

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `Testing`,
};

export default function Header({ siteTitle }) {
  const [nav, showNav] = React.useState(false);
  return (
    <SiteHeader>
      <TitleContainer>
        <SiteTitle>
          MB<span className="ornament bullet">&#8226;</span>MB
        </SiteTitle>
        <WeddingDate>
          10<span className="ornament">&#47;</span>02
          <span className="ornament ss02">&#47;</span>21
        </WeddingDate>
      </TitleContainer>

      <SiteNav>
        <div className="button-container">
          <button
            onClick={() => showNav(!nav, console.log(nav))}
            className={nav ? "menu-toggle active" : "menu-toggle"}
          >
            {nav ? "Close" : "Menu"}
          </button>
        </div>
        <ul className={nav ? "nav-list visible" : "nav-list"}>
          <li>
            <Link onClick={() => showNav(!nav, console.log(nav))} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => showNav(!nav, console.log(nav))}
              to="/location"
            >
              Location
            </Link>
          </li>
          <li>
            <Link
              onClick={() => showNav(!nav, console.log(nav))}
              to="/schedule"
            >
              Schedule
            </Link>
          </li>

          <li>
            <Link
              onClick={() => showNav(!nav, console.log(nav))}
              to="/transportation"
            >
              Transportation
            </Link>
          </li>

          <li>
            <Link onClick={() => showNav(!nav, console.log(nav))} to="/lodging">
              Places to Stay
            </Link>
          </li>

          <li>
            <Link
              onClick={() => showNav(!nav, console.log(nav))}
              to="/things-to-do"
            >
              Things to Do
            </Link>
          </li>

          <li>
            <Link
              onClick={() => showNav(!nav, console.log(nav))}
              to="/sf-culture"
            >
              SF Culture
            </Link>
          </li>

          <li>
            <Link
              onClick={() => showNav(!nav, console.log(nav))}
              to="/registry"
            >
              Registry
            </Link>
          </li>

          <li>
            <Link onClick={() => showNav(!nav, console.log(nav))} to="/covid">
              Covid Safety
            </Link>
          </li>

          <li>
            <Link onClick={() => showNav(!nav, console.log(nav))} to="/rsvp">
              RSVP
            </Link>
          </li>
        </ul>
      </SiteNav>
    </SiteHeader>
  );
}
